<template>
	<div class="main-component">
		<div class="title" v-html="data.title"></div>
	</div>
</template>

<script>
import { useStore } from 'vuex';
import { reactive, computed } from 'vue';

export default {
	setup() {
		const store = useStore();

		const data = reactive({
			title_ge: '<h1 class="font-89 geo-title">სინაფსური <br /> გადაწყვეტილებები</h1>',

			title: '<h1 class="font-89 eng-title">Synaptic Solutions</h1>',
		});

		return {
			data,
			currentLang: computed(() => store.state.lang),
		};
	},
};
</script>

<style>
.main-component {
	display: flex;
	max-height: 911px;
	height: 95vh;
	align-items: center;
	justify-content: center;
}
.main-component .title {
	height: 100%;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	max-width: 900px;
}
@media (min-width: 1366px) {
	.main-component {
		transform: translateY(55px);
	}
}
@media (max-width: 1023px) {
	.main-component .title {
		max-width: 90%;
	}
}
@media (max-width: 767px) {
	.title .font-89 {
		font-size: 34px;
	}
	.title .eng-title {
		max-width: 240px;
	}
	.title .geo-title {
		font-size: 34px;
		max-width: 300px;
	}
	.main-component {
		height: 300px;
		padding-top: 89px;
		transform: translateY(100px);
	}
}
@media (max-width: 413px) {
	.main-component {
		height: 50vh;
		padding-top: 0;
		transform: translateY(55px);
	}
}
</style>
