<template>
	<header>
		<video class="video" autoplay loop muted>
			<source src="../assets/images/video.mp4" type="video/mp4" />
		</video>
		<div class="cover"></div>
		<div class="flex-between header-container container" :class="{ scrolled: isScrolled }">
			<div class="header-socials flex-box">
				<a
					:key="index"
					:href="item.link"
					v-for="(item, index) in data.icons"
					target="_blank"
					class="social-item pointer"
				>
					<IconComponent :name="item.title" />
				</a>
			</div>
			<div class="header-logo">
				<IconComponent class="logo" name="header-logo" />

				<IconComponent class="mobile-logo" name="mobile-header-logo" />

				<div class="logo-text logo" v-html="data.title"></div>
			</div>

			<div class="lang-selector">
				<div
					v-for="(item, index) in data.lang"
					:key="index"
					:class="$store.state.lang == item.title ? 'active' : ''"
					class="single-lang pointer"
					@click="this.$store.commit('changeLang', item)"
				>
					{{ item.title }}
				</div>
			</div>
		</div>
	</header>
</template>

<script>
import { onMounted, toRefs, reactive, computed } from 'vue';
import { useStore } from 'vuex';
import IconComponent from '../components/IconComponent.vue';

export default {
	components: {
		IconComponent,
	},

	setup() {
		const store = useStore();

		const data = reactive({
			icons: [
				{
					id: 1,
					title: 'facebook',
					link: 'https://www.facebook.com/Synaptic-Solutions-104250302160411/',
				},
				{ id: 2, title: 'instagram', link: 'https://www.instagram.com/synaptic.solutions/' },
			],
			title_ge: '<p>კარდიო და <br /> პაციენტებზე ზრუნვა</p>',

			title: '<p>Elevate Healthcare and <br /> Patient Care</p>',

			lang: [
				// { id: 1, title: 'Ge' },
				{ id: 2, title: 'En' },
			],
		});

		return {
			data,
			currentLang: computed(() => store.state.lang),
		};
	},
	data() {
		return {
			isScrolled: false,
		};
	},
	methods: {
		handleScroll(event) {
			if (window.pageYOffset > 20) {
				this.isScrolled = true;
			} else {
				this.isScrolled = false;
			}
		},
	},
	created() {
		window.addEventListener('scroll', this.handleScroll);
	},
	destroyed() {
		window.removeEventListener('scroll', this.handleScroll);
	},
};
</script>

<style>
header {
	top: 0;
	z-index: 1;
	width: 100%;
	position: fixed;
	background: linear-gradient(to bottom, #ecedf1 0%, #43b1e9 0%);
}
.video {
	top: 50%;
	left: 50%;
	z-index: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	position: absolute;
	transform: translate(-50%, -50%);
}
.cover {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 100%;
	height: 100%;
	z-index: 1;
	transform: translate(-50%, -50%);
	background: rgba(67, 177, 233, 0.85);
}
.header-container {
	padding: 50px 0;
	position: relative;
	z-index: 2;
	border-bottom: 1px solid white;
	transition: 0.4s ease-in-out;
}

.social-item:not(:last-child) {
	margin-right: 20px;
}
.header-logo {
	display: flex;
	align-items: center;
	flex-direction: column;
}
.header-logo .logo {
	margin-bottom: 22px;
	transition: 0.4s ease-in-out;
}
.logo-text {
	color: #fff;
	text-align: center;
	height: 48px;
	transition: opacity 0.4s ease-in-out, height 0.4s ease-in-out;
}
.lang-selector {
	display: flex;
	align-items: center;
}
.single-lang:not(:last-child) {
	margin-right: 10px;
}
.single-lang {
	color: #fff;
	opacity: 0.5;
}
.single-lang.active {
	opacity: 1;
}
.mobile-logo {
	display: none;
}
.logo {
	display: block;
}
.scrolled {
	padding: 15px 0;
}
.scrolled .logo {
	margin-bottom: 0;
	transition: 0.2s ease-in-out;
}
.scrolled .logo-text {
	height: 0;
	opacity: 0;
	transition: opacity 0.2s ease-in-out, height 0.4s ease-in-out;
}
@media (max-width: 767px) {
	.mobile-logo {
		display: block;
	}
	.logo {
		display: none;
	}
	.header-container {
		padding: 30px 0;
	}
}
</style>
