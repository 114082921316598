<template>
  <div>
    <Header />

    <div class="background" id="mainBlock">
      <IconComponent class="main-logo" name="main-logo" />
      <IconComponent class="logo-marker" name="logo-marker" />
      <IconComponent class="logo-marker" name="scroll-marker" />

      <router-view />
    </div>

    <Footer />
  </div>
</template>
<script>
import Header from "./layout/Header.vue";
import Footer from "./layout/Footer.vue";
import IconComponent from "./components/IconComponent.vue";
export default {
  components: {
    Header,
    Footer,
    IconComponent,
  },
  setup() {},
};
</script>
<style>
.background {
  position: relative;
  margin-top: 299px;
  background: linear-gradient(to top, #fff 28%, #43b1e9 100%);
}
.main-logo,
.logo-marker {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  right: 50%;
  bottom: 50%;
  height: 100%;
  padding-top: 89px;
  transform: translate(-50%, -50%);
  z-index: 0;
}
.mount-svg {
  top: 50%;
  left: 50%;
  right: 40%;
  width: 100%;
  bottom: 50%;
  z-index: 99;
  height: 100%;
  padding-top: 89px;
  position: absolute;
  transform: translate(-40%, -50%);
  z-index: 0;
}
.logo-marker svg path {
  opacity: 0;
}
.mount-svg svg {
  width: 100%;
}
.main-logo svg,
.logo-marker svg {
  width: 100%;
  height: 110%;
  overflow: visible;
}
@media (max-width: 1599px) {
  .main-logo,
  .logo-marker {
    padding-top: 55px;
  }
}
@media (max-width: 767px) {
  .background {
    margin-top: 137px;
    padding-bottom: 34px;
  }
  .main-logo svg,
  .logo-marker svg {
    padding: 0 10px;
    /* height: auto; */
  }
}
@media (max-width: 413px) {
  .main-logo,
  .logo-marker {
    padding-top: 55px;
  }

  .main-logo svg,
  .logo-marker svg {
    width: 100%;
    height: 100%;
    overflow: visible;
  }
}
</style>
