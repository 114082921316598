<template>
  <footer>
    <div class="contact contact-container">
      <img class="footer-svg" src="../assets/images/logo.png" alt="" />

      <h2 class="font-21">
        {{ data.contact.title }}
      </h2>

      <div class="flex-between gap">
        <div
          v-for="(item, index) in data.contact.listData"
          :key="index"
          class="single-info"
        >
          <p class="font-16" v-html="item.title"></p>
          <div class="font-21" v-html="item.content"></div>
        </div>
      </div>
    </div>
    <div class="footer-main">
      <div class="container flex-between">
        <div class="flex-box">
          <div class="footer-logo">
            <img src="../assets/images/logo.png" />
          </div>

          <p class="font-21 rights">
            {{ "© 2022 All rights reserved" }}
          </p>
        </div>

        <a href="https://www.connect.ge/en/" target="_blank">
          <IconComponent name="connect" />
        </a>
      </div>
    </div>
  </footer>
</template>

<script>
import IconComponent from "../components/IconComponent.vue";
import { reactive, computed } from "vue";
import { useStore } from "vuex";

export default {
  components: {
    IconComponent,
  },
  setup() {
    const store = useStore();

    const data = reactive({
      contact: {
        title_ge: "კონტაქტი",
        title: "contact",

        listData: [
          {
            id: 1,
            title: "Write to us",
            title_ge: "მოგვწერეთ",
            content_ge:
              '<a href="mailto: info@synapticsolutions.ge">info@synapticsolutions.ge</a>',
            content:
              '<a href="mailto: info@synapticsolutions.ge">info@synapticsolutions.ge</a>',
          },
          {
            id: 2,
            title: "Our Address",
            title_ge: "მისამართი",
            content: "<p>25 Kekelidze str., Tbilisi, Georgia</p>",
            // content_ge: '<p>სარ. 89, 25 კეკელიძის., თბილისი, საქართველო</p>',
          },
          {
            id: 2,
            title: "Call us",
            title_ge: "დაგვირეკეთ",
            content_ge:
              '<a href="tel: +995 577 730 009"> +995 577 730 009 </a>',
            content: '<a href="tel: +995 577 730 009"> +995 577 730 009 </a>',
          },
        ],
      },
    });

    return {
      data,
      currentLang: computed(() => store.state.lang),
    };
  },
};
</script>

<style>
footer {
  padding: 89px 0 55px;
  background: linear-gradient(to bottom, #fff 85%, #33bee7 140%);
}
.contact {
  position: relative;
  margin-bottom: 315px;
}
.contact .gap {
  gap: 30px;
}
.contact svg {
  position: absolute;
  right: 0%;
  top: -100%;
  transform: translate(50%, -50%);
}
.contact h2 {
  margin-bottom: 25px;
}
.single-info p {
  line-height: 19px;
  margin-bottom: 7px;
}
.single-info a {
  color: #000000;
  text-decoration: none;
}
.footer-main .rights {
  margin-left: 20px;
}
.footer-main .flex-box {
  align-items: flex-end;
}
.footer-logo {
  width: 95px;
  height: 45px;
  position: relative;
}
.footer-logo img {
  width: 100%;
  height: 100%;
}
.footer-svg {
  position: relative;
}
.footer-svg {
  right: -20%;
  width: 45%;
  top: -100%;
  position: absolute;
  filter: opacity(0.05);
}

@media (min-width: 1024px) {
  .contact-container {
    padding-top: 89px;
  }
}
@media (max-width: 1899px) {
  .footer-svg {
    right: -25%;
    width: 45%;
    top: -70%;
  }
}
@media (max-width: 1599px) {
  .footer-svg {
    top: -50%;
  }
}
@media (max-width: 1365px) {
  .single-info a,
  .single-info p {
    font-size: 16px;
  }
}
@media (max-width: 1023px) {
  .footer-svg {
    top: 80%;
    right: -50%;
    width: 100%;
  }
  footer {
    padding: 189px 0 55px;
  }
  /* .single-info a,
	.single-info p {
		font-size: 21px;
	} */
  .gap {
    flex-direction: column;
    align-items: flex-start !important;
  }

  .contact svg {
    top: 0%;
    right: 0%;
    bottom: 0%;
    position: absolute;
    transform: translate(50%, 50%);
  }
}
@media (max-width: 767px) {
  footer {
    padding: 89px 0 55px;
  }
  .footer-logo {
    display: none;
  }
  .footer-svg {
    top: 70%;
  }
  .contact svg {
    top: 0%;
    right: 0%;
    bottom: 0%;
    width: 100%;
    position: absolute;
    transform: translate(30%, 30%);
  }
}
</style>
