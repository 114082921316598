<template>
  <div class="about">
    <h2 class="font-21" v-html="data.title"></h2>
    <p class="font-21 text lh-32" v-html="data.content"></p>
  </div>
</template>
<script>
import { useStore } from "vuex";
import { reactive, computed } from "vue";

export default {
  setup() {
    const store = useStore();

    const data = reactive({
      title_ge: '<h2 class="font-21">ჩვენს შესახებ</h2>',

      title: '<h2 class="font-21">About</h2>',

      content_ge:
        '<p class="font-21 lh-32">The group of company that supports biotech implementation through collaboration, education and legal support in the healthcare system of the developing countries of the Caucasus Region. Technological evaluation of biotechnologies in 21st century gives completely new perspective for healthcare professionals. Our mission is to elevate healthcare and patient care standards in several countries.</p>',
      content:
        '<p class="font-21 lh-32">The group of company that supports biotech implementation through collaboration, education and legal support in the healthcare system of the developing countries of the Caucasus Region. Technological evaluation of biotechnologies in 21st century gives completely new perspective for healthcare professionals. Our mission is to elevate healthcare and patient care standards in several countries.</p>',
    });

    return {
      data,
      currentLang: computed(() => store.state.lang),
    };
  },
};
</script>

<style scoped>
.about {
  padding: 89px 0 280px;
  max-width: 670px;
  margin: 0 auto;
  transform: translateX(-50%);
  min-height: 70vh;
  transition: 0.4s ease-in-out;
}
h2 {
  margin-bottom: 22px;
}
.text {
  text-align: justify;
}
@media (max-width: 1599px) {
  .about {
    max-width: 460px;
    transform: translate(-40%, 15%);
    min-height: 100vh;
    max-height: 780px;
  }
}
@media (max-width: 1023px) {
  .about {
    transform: translate(-0%, 0%);
    min-height: 70vh;
    margin-left: 42px;
    margin-right: calc(42px);
  }
}
@media (max-width: 767px) {
  .about {
    min-height: 50vh;
    padding: 55px 0 200px;
    /* transform: translate(-0%, 25%); */
    transform: translate(10%, 37%);
  }
  .about >>> p.font-21 {
    font-size: 14px;
    line-height: 25px;
    max-width: 90%;
  }
}
@media (max-width: 413px) {
  .about {
    padding: 55px 0 0;
    transform: translate(-0%, 0%);
  }
}
</style>
