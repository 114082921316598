import { createStore } from 'vuex';

export default createStore({
	state: {
		lang: 'En',
	},
	mutations: {
		changeLang(state, lang) {
			state.lang = lang.title;
		},
	},
	actions: {},
	modules: {},
});
