<template>
	<div class="main">
		<Main />

		<About />
	</div>
</template>

<script>
// @ is an alias to /src

import Main from '../components/Main.vue';
import About from '../components/About.vue';

export default {
	name: 'Landing',
	components: {
		Main,
		About,
	},
};
</script>
